<template>
  <BackofficeBase>
    <h1>File Uploader</h1>
    <form>
      <input type="file" v-on:change="file_upload_1_change" ref="file_upload_1"><br>
      <div v-if="uploading">Uploading...</div>
      <img class="img_pre" v-if="show_img" :src="src" />
    </form>
  </BackofficeBase>
</template>

<style>
.img_pre {
  max-width: 50em;
  margin-top: 1em;
}
</style>

<script>
import BackofficeBase from "./BackofficeBase"
import BackendModel from "../../models/BackendModel"

export default {
  name: 'BackofficeFileUploader',
  components: {
    BackofficeBase
  },
  data() {
    return {
      file_upload_1 : '',
      uploading : false,
      show_img : false,
      src : ""
    }
  },
  methods : {
    async file_upload_1_change() {
      this.uploading = true
      this.show_img = false
      this.file_upload_1 = this.$refs.file_upload_1.files[0]
      let formData = new FormData()
      formData.append('upload_preset', 'ml_default')
      formData.append('file', this.file_upload_1)
      formData.append('cloud_name', 'zero21-innovation')
      let backendModel = new BackendModel()
      let res = await backendModel.backendRequest("/Api/service/backoffice/s3_upload_file", formData)
      console.log(res)
      this.uploading = false
      this.show_img = true
      this.src = res.data.url
    }
  }
}
</script>
